import { render, staticRenderFns } from "./ActionTextInput.vue?vue&type=template&id=f2f65236&scoped=true"
import script from "./ActionTextInput.vue?vue&type=script&lang=js"
export * from "./ActionTextInput.vue?vue&type=script&lang=js"
import style0 from "./ActionTextInput.vue?vue&type=style&index=0&id=f2f65236&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2f65236",
  null
  
)

export default component.exports