import { render, staticRenderFns } from "./WidgetPrivacyPreview.vue?vue&type=template&id=e7af8922&scoped=true"
import script from "./WidgetPrivacyPreview.vue?vue&type=script&lang=js"
export * from "./WidgetPrivacyPreview.vue?vue&type=script&lang=js"
import style0 from "./WidgetPrivacyPreview.vue?vue&type=style&index=0&id=e7af8922&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7af8922",
  null
  
)

export default component.exports